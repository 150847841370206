@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@500;700&family=Josefin+Sans:wght@300;400;600&display=swap");

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-self: center;
}

#root {
  background: radial-gradient(circle, #0e160d 0%, #0f290b 21%, #000000 100%);
}

canvas {
  position: fixed;
}

.html-root {
  position: absolute;
  width: 100%;
  height: 400%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: #000;
}

.loader-html {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-gif {
  background-image: url("/public/Assets/Images/LoaderGIF.gif");
  width: 512px;
  height: 232px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.initiator {
  width: 100%;
  height: 30%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e9fce9;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0.5px;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}

.initiator-quote {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 63px;
  opacity: 0;
  visibility: hidden;
  text-transform: uppercase;
  font-size: 14px;
}

.initiator-quote-line {
  margin: 3px;
}

@keyframes glow {
  from {
    text-shadow: -2px 0 10px rgba(0, 0, 0, 0.4), 0 0 5px #aee9ae,
      0 0 10px #61b161, 0 0 15px #46b246;
  }
  to {
    text-shadow: -2px 0 10px rgba(0, 0, 0, 0.4), 0 0 25px #aee9ae,
      0 0 40px #61b161, 0 0 30px #84ec84;
  }
}

.initiator-button {
  margin-top: 36px;
  font-weight: 900;
  letter-spacing: 3px;
  padding: 9px;
  cursor: pointer;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;
  font-size: 18px;
  animation: glow 1s infinite alternate ease-in-out;
}

.initiator-button:hover {
  filter: drop-shadow(0 0 2px #a9ffa9) drop-shadow(0 0 5px #4eff4e);
}

.html-full-es {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: fixed;
  background-color: #000;
}

.logo-main {
  position: fixed;
  display: flex;
  top: 100px;
  left: 100px;
  opacity: 0;
  height: 16px;
  width: 80px;
}

.logo-letter {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.s {
  background-image: url(../public/Assets/Images/S.png);
}

.e {
  background-image: url(../public/Assets/Images/E.png);
}

.d {
  background-image: url(../public/Assets/Images/D.png);
}

.left {
  width: 33.33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center {
  width: 33.33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right {
  width: 33.33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.for-scroll-options {
  height: 100%;
  width: 2px;
  display: flex;
  flex-direction: column;
}

.section {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  justify-content: start;
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 27%;
  width: 18%;
  bottom: 2%;
}

@keyframes scrolldown {
  0%,
  100% {
    transform: translateY(-14px);
    color: #6c6c6c;
  }
  50% {
    transform: translateY(-3px);
    color: #e9fce9;
  }
}

.scroll-down {
  height: 14px;
  width: 120px;
  font-size: 9px;
  color: #e9fce9;
  font-family: "Josefin Sans", sans-serif;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  opacity: 0;
  box-sizing: border-box;
  animation-name: scrolldown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.copyright {
  position: fixed;
  left: 5px;
  bottom: 0px;
  color: #a9a9a9;
  font-family: "Josefin Sans", sans-serif;
  font-size: 9px;
}

.nav-title-a {
  color: #e9fce9;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: right;
  opacity: 0;
}

.nav-title-b {
  color: rgb(233, 252, 233);
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: left;
  opacity: 0;
}

.cube-grid-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 98%;
  height: 98%;
}

.cube-visible {
  border: 0.5px solid #1e251dcc;
  color: #2a3429cc;
  font-size: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 3px;
  cursor: pointer;
  transition: all 1s;
  font-family: "Josefin Sans", sans-serif;
}

.cube-initial {
  border: 0.5px solid #909090;
  color: #6f6f6f;
}

.cube-visible:hover {
  border: 0.5px solid rgba(230, 255, 230, 0.9);
  color: rgb(255, 255, 255);
  transition: all 1s;
  box-shadow: 1px 1px 18px -6px rgba(230, 255, 230, 0.9);
}

.cube-glow {
  border: 0.5px solid rgba(230, 255, 230, 0.9);
  color: rgb(255, 255, 255);
  box-shadow: 1px 1px 18px -6px rgba(230, 255, 230, 0.9);
}

.cube-visible-selected {
  border: 0.5px solid rgba(230, 255, 230, 0.9);
  color: rgb(255, 255, 255);
  transition: all 1s;
  box-shadow: 1px 1px 18px -6px rgba(230, 255, 230, 0.9);
}

.button-more {
  position: fixed;
  left: 10px;
  bottom: 20px;
}

.scroll-back {
  border: 0.5px solid #909090;
  color: #6f6f6f;
  font-size: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 3px;
  cursor: pointer;
  transition: all 1s;
  font-family: "Josefin Sans", sans-serif;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  right: 50%;
  bottom: 10px;
}

.scroll-back:hover {
  border: 0.5px solid rgba(230, 255, 230, 0.9);
  color: rgb(255, 255, 255);
  transition: all 1s;
  box-shadow: 1px 1px 18px -6px rgba(230, 255, 230, 0.9);
}

#button-A {
  transform-origin: bottom;
  transform: rotateX(-90deg);
}

#button-B {
  transform-origin: right;
  transform: rotateY(-90deg);
}

#button-F {
  transform-origin: top;
  transform: rotateX(-90deg);
}

#button-D {
  transform-origin: left;
  transform: rotateY(-90deg);
}

#button-E {
  transform-origin: left;
  transform: rotateY(-90deg);
}

.display-board-left {
  color: #e9fce9;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  height: 100%;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  width: 33.33%;
  text-align: justify;
}

.display-board-right {
  color: #e9fce9;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  width: 33.33%;
  height: 100%;
  text-align: justify;
  flex-direction: column;
}

.tagline {
  color: rgb(233, 252, 233);
  font-size: 30px;
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 700;
  z-index: -1;
  opacity: 1;
  position: fixed;
  bottom: 6%;
  left: 30px;
}

.headline {
  color: #e9fce9;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  opacity: 1;
  position: fixed;
  top: 15%;
  z-index: -1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headline-LHS {
  width: 50%;
  text-align: right;
}

.headline-RHS {
  width: 50%;
  text-align: left;
}

.pb {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
}

.pb-0 {
  background-image: url("/public/Assets/Images/PB-0.png");
}
.pb-1 {
  background-image: url("/public/Assets/Images/PB-1.png");
}
.pb-2 {
  background-image: url("/public/Assets/Images/PB-2.png");
}
.pb-3 {
  background-image: url("/public/Assets/Images/PB-3.png");
}
.pb-4 {
  background-image: url("/public/Assets/Images/PB-4.png");
}
.pb-5 {
  background-image: url("/public/Assets/Images/PB-5.png");
}
.pb-6 {
  background-image: url("/public/Assets/Images/PB-6.png");
}
.pb-7 {
  background-image: url("/public/Assets/Images/PB-7.png");
}
.pb-8 {
  background-image: url("/public/Assets/Images/PB-8.png");
}
.pb-9 {
  background-image: url("/public/Assets/Images/PB-9.png");
}

.year {
  font-size: 10px;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.material-icons {
  font-size: 30px;
}

/** PAGE S **/

.pageS-headlineLHS {
  color: #e9fce9;
}

.pageS-headlineRHS {
  color: #beffbe;
}

.pageS-board-right {
  position: fixed;
  width: 36%;
  top: 72%;
  text-align: center;
}

.pageS-board-left {
  position: fixed;
  width: 18%;
  left: 7%;
  height: 40%;
  top: 30%;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: #e9fce9;
  position: relative;
  pointer-events: all;
}

/** PAGE A HORIZON**/

.pageA-headlineLHS {
  color: #beffbe;
}

.pageA-headlineRHS {
  color: #e9fce9;
}

.pageA-board-right {
  position: fixed;
  width: 25%;
  left: 7%;
  top: 72%;
}

.pageA-board-left {
  position: fixed;
  width: 25%;
  left: 7%;
  height: 40%;
  top: 30%;
}

/** PAGE B GENESIS**/

.pageB-headlineLHS {
  color: #beffbe;
}

.pageB-headlineRHS {
  color: #e9fce9;
}

.pageB-board-right {
  position: fixed;
  width: 25%;
  left: 7%;
  top: 72%;
}

.pageB-board-left {
  position: fixed;
  width: 25%;
  left: 7%;
  height: 40%;
  top: 20%;
}

.sl {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50%;
  width: 25%;
  position: fixed;
  opacity: 0;
}
.sl-0 {
  background-image: url("/public/Assets/Images/SL-1.png");
  z-index: 10;
}

.sl-1 {
  background-image: url("/public/Assets/Images/SL-2.png");
  z-index: 9;
}
.sl-2 {
  background-image: url("/public/Assets/Images/SL-3.png");
  z-index: 8;
}
.sl-3 {
  background-image: url("/public/Assets/Images/SL-4.png");
}

/** PAGE C THRESHOLD**/

.pageC-headlineLHS {
  color: #beffbe;
}

.pageC-headlineRHS {
  color: #e9fce9;
}

.pageC-board-right {
  position: fixed;
  width: 33%;
  left: 7%;
  top: 80%;
  text-align: center;
}

.pageC-board-left {
  position: fixed;
  width: 33%;
  left: 7%;
  height: 40%;
  top: 30%;
  height: 50%;
}

/** PAGE D SPACE**/

.pageD-headlineLHS {
  color: #e9fce9;
}

.pageD-headlineRHS {
  color: #beffbe;
}

.pageD-board-right {
  position: fixed;
  width: 33%;
  right: 10%;
  top: 20%;
  text-align: center;
}

.pageD-board-left {
  position: fixed;
  width: 29%;
  left: 4%;
  height: 40%;
  top: 30%;
  height: 50%;
}

.ff {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50%;
  width: 50%;
  position: fixed;
  opacity: 0;
}

.ff-0 {
  background-image: url("/public/Assets/Images/FF-1.png");
}

.ff-1 {
  background-image: url("/public/Assets/Images/FF-2.png");
}

.ff-2 {
  background-image: url("/public/Assets/Images/FF-3.png");
}

.ff-3 {
  background-image: url("/public/Assets/Images/FF-4.png");
}

.ff-4 {
  background-image: url("/public/Assets/Images/FF-5.png");
}

.ff-5 {
  background-image: url("/public/Assets/Images/FF-6.png");
}

.ff-6 {
  background-image: url("/public/Assets/Images/FF-7.png");
}

.ff-7 {
  background-image: url("/public/Assets/Images/FF-8.png");
}

.highlight {
  color: #beffbe;
  letter-spacing: 1px;
}

li {
  margin-bottom: 5px;
}

.logo-for-sector {
  background-image: url("/public/Assets/Images/SEED.png");
  height: 250px;
  width: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px;
}

/** PAGE E INSIGHTS**/

.pageE-headlineLHS {
  color: #e9fce9;
}

.pageE-headlineRHS {
  color: #beffbe;
}

.pageE-board-right {
  position: fixed;
  width: 33%;
  right: 10%;
  top: 80%;
  text-align: justify;
}

.pageE-board-left {
  position: fixed;
  width: 25%;
  left: 4%;
  height: 40%;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pl-exodus {
  height: 100%;
  width: 100%;
  margin-bottom: auto;
}

.pl-ecolith {
  height: 100%;
  width: 100%;
  margin-bottom: auto;
}

.pl {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10%;
  width: 25%;
  position: fixed;
  opacity: 0;
  cursor: pointer;
  pointer-events: all;
}

.pl-0 {
  background-image: url("/public/Assets/Images/PL-1.png");
}
.pl-1 {
  background-image: url("/public/Assets/Images/PL-2.png");
}
.pl-2 {
  background-image: url("/public/Assets/Images/PL-3.png");
}
.pl-3 {
  background-image: url("/public/Assets/Images/PL-4.png");
}
.pl-4 {
  background-image: url("/public/Assets/Images/PL-5.png");
}

.pl-5 {
  background-image: url("/public/Assets/Images/EC-1.png");
}
.pl-6 {
  background-image: url("/public/Assets/Images/EC-2.png");
}
.pl-7 {
  background-image: url("/public/Assets/Images/EC-3.png");
}
.pl-8 {
  background-image: url("/public/Assets/Images/EC-4.png");
}
.pl-9 {
  background-image: url("/public/Assets/Images/EC-5.png");
}
.pl-10 {
  background-image: url("/public/Assets/Images/EC-6.png");
}
.pl-11 {
  background-image: url("/public/Assets/Images/EC-7.png");
}

/** PAGE F HARMONY**/

.pageF-headlineLHS {
  color: #beffbe;
}

.pageF-headlineRHS {
  color: #e9fce9;
}

.pageF-board-right {
  position: fixed;
  width: 33%;
  left: 4%;
  top: 50%;
  text-align: center;
}

.pageF-board-left {
  position: fixed;
  width: 25%;
  left: 4%;
  top: 30%;
  height: 50%;
}

/** PAGE G **/

.pageG-headlineLHS {
  color: #e9fce9;
}

.pageG-headlineRHS {
  color: #beffbe;
}

.pageG-board-right {
  position: fixed;
  width: 33%;
  left: 10%;
  top: 55%;
  text-align: justify;
}

.pageG-board-left {
  position: fixed;
  width: 25%;
  left: 4%;
  top: 20%;
  height: 30%;
}

.whatsapp-code {
  background-image: url("/public/Assets/Images/QRCODE.jpeg");
  height: 250px;
  width: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px;
}

.powered {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: -55vw;
  bottom: 0px;
}

.sense-logo {
  background-image: url("/public/Assets/Images/sense-logo.png");
  height: 80px;
  width: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 5px;
}

/********************* 6" to 7" phone (portrait) DONE **************************/

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .logo-main {
    top: 36px;
    left: 36px;
    font-size: 15px;
  }

  #root {
    background: radial-gradient(circle, #0e160d 0%, #0a1a07 5%, #000000 100%);
  }

  .image-gif {
    width: 280px;
    height: 200px;
  }

  .navigation {
    height: 18%;
    width: 45%;
    bottom: 7%;
    right: 5%;
  }

  .nav-title-a {
    font-size: 11px;
  }

  .nav-title-b {
    font-size: 11px;
  }

  .scroll-down {
    font-size: 7px;
    left: 45px;
  }

  .copyright {
    font-size: 6px;
  }

  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 14px;
  }

  .pageS-headlineRHS {
    font-size: 27px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 14px;
    right: 0px;
  }

  .pageS-board-left {
    width: 65%;
    font-size: 14px;
  }
  .tagline {
    font-size: 18px;
    bottom: 2%;
    left: 10px;
  }

  /** PAGE A HORIZON  **/

  .pageA-headlineLHS {
    font-size: 13px;
  }

  .pageA-headlineRHS {
    font-size: 10px;
    text-align: center;
  }

  .pageA-board-right {
    width: 80%;
    font-size: 14px;
    margin-left: 10px;
  }

  .pageA-board-left {
    width: 80%;
    height: 40%;
    margin-left: 10px;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 14px;
  }

  .pageB-headlineRHS {
    font-size: 12px;
  }

  .pageB-board-right {
    width: 80%;
    font-size: 14px;
    margin-left: 10px;
  }

  .pageB-board-left {
    width: 80%;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .sl {
    height: 50%;
    width: 60%;
  }

  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 14px;
  }

  .pageC-headlineRHS {
    font-size: 14px;
  }

  .pageC-board-right {
    width: 90%;
    font-size: 16px;
  }

  .pageC-board-left {
    width: 88%;
    height: 50%;
  }

  /** PAGE D SPACE **/

  .pageD-headlineLHS {
    font-size: 14px;
  }

  .pageD-headlineRHS {
    font-size: 12px;
    text-align: center;
  }

  .pageD-board-right {
    width: 80%;
    height: 60%;
    top: 50%;
    right: 0%;
  }

  .pageD-board-left {
    width: 90%;
    height: 60%;
    font-size: 12px;
  }
  .ff {
    height: 70%;
    width: 70%;
  }

  .logo-for-sector {
    background-image: url("/public/Assets/Images/SEED.png");
    height: 180px;
    width: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 70px;
  }

  /** PAGE E INSIGHTS **/

  .pageE-headlineLHS {
    font-size: 14px;
  }

  .pageE-headlineRHS {
    font-size: 12px;
  }

  .pageE-board-right {
    width: 80%;
    height: 60%;
    top: 70%;
    font-size: 14px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 7%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 80%;
  }

  .pl {
    height: 10%;
    width: 50%;
  }

  /** PAGE F HARMONY **/

  .pageF-headlineLHS {
    font-size: 14px;
  }

  .pageF-headlineRHS {
    font-size: 9px;
    text-align: center;
  }

  .pageF-board-right {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }

  .pageF-board-left {
    width: 85%;
    height: 40%;
    font-size: 14px;
    margin-left: 10px;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 14px;
  }

  .pageG-headlineRHS {
    font-size: 12px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 14px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: -60px;
    bottom: -80px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 6" to 7" phone (landscape) DONE **************************/

@media only screen and (min-width: 649px) and (max-width: 899px) and (orientation: landscape) {
  .logo-main {
    top: 36px;
    left: 36px;
    font-size: 15px;
  }

  #root {
    background: radial-gradient(circle, #0e160d 0%, #0a1a07 5%, #000000 100%);
  }

  .image-gif {
    width: 261px;
    height: 180px;
  }

  .navigation {
    height: 18%;
    width: 22%;
    bottom: 20%;
    right: 5%;
  }

  .nav-title-a {
    font-size: 12px;
  }

  .nav-title-b {
    font-size: 12px;
  }

  .copyright {
    font-size: 6px;
  }

  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 14px;
  }

  .pageS-headlineRHS {
    font-size: 27px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 14px;
    right: -10%;
  }

  .pageS-board-left {
    width: 65%;
    font-size: 14px;
  }
  .tagline {
    font-size: 18px;
    bottom: 2%;
    left: 10px;
  }

  /** PAGE A HORIZON  **/

  .pageA-headlineLHS {
    font-size: 14px;
  }

  .pageA-headlineRHS {
    font-size: 10px;
  }

  .pageA-board-right {
    width: 80%;
    font-size: 14px;
    margin-left: 10px;
  }

  .pageA-board-left {
    width: 80%;
    height: 40%;
    margin-left: 10px;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 14px;
  }

  .pageB-headlineRHS {
    font-size: 12px;
  }

  .pageB-board-right {
    width: 80%;
    font-size: 14px;
    margin-left: 10px;
  }

  .pageB-board-left {
    width: 80%;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .sl {
    height: 50%;
    width: 60%;
  }

  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 14px;
  }

  .pageC-headlineRHS {
    font-size: 14px;
  }

  .pageC-board-right {
    width: 90%;
    font-size: 16px;
  }

  .pageC-board-left {
    width: 88%;
    height: 50%;
  }

  /** PAGE D SPACE **/

  .pageD-headlineLHS {
    font-size: 14px;
  }

  .pageD-headlineRHS {
    font-size: 12px;
  }

  .pageD-board-right {
    width: 65%;
    height: 60%;
    top: 40%;
    right: -10%;
  }

  .pageD-board-left {
    width: 50%;
    height: 60%;
    font-size: 12px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  .logo-for-sector {
    background-image: url("/public/Assets/Images/SEED.png");
    height: 180px;
    width: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 70px;
  }

  /** PAGE E INSIGHTS **/

  .pageE-headlineLHS {
    font-size: 14px;
  }

  .pageE-headlineRHS {
    font-size: 12px;
  }

  .pageE-board-right {
    width: 80%;
    height: 60%;
    top: 70%;
    font-size: 14px;
  }

  .pageE-board-left {
    width: 65%;
    height: 80%;
    top: 30%;
    left: 7%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 40%;
  }

  .pl {
    height: 10%;
    width: 50%;
  }

  /** PAGE F HARMONY **/

  .pageF-headlineLHS {
    font-size: 14px;
  }

  .pageF-headlineRHS {
    font-size: 12px;
    text-align: left;
  }

  .pageF-board-right {
    width: 80%;
    display: flex;
    justify-content: center;
    left: 10%;
  }

  .pageF-board-left {
    width: 80%;
    height: 40%;
    font-size: 14px;
    left: 10%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 14px;
  }

  .pageG-headlineRHS {
    font-size: 12px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 14px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 20px;
    bottom: 20px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 7" to 9" tablets (portrait) DONE **************************/

@media only screen and (min-width: 650px) and (max-width: 768px) and (orientation: portrait) {
  .logo-main {
    top: 45px;
    left: 45px;
  }

  #root {
    background: radial-gradient(circle, #0e160d 0%, #0a1a07 5%, #000000 100%);
  }

  .navigation {
    height: 21%;
    width: 36%;
    bottom: 2%;
    right: 5%;
  }
  .material-icons {
    font-size: 22px;
  }
  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 16px;
  }

  .pageS-headlineRHS {
    font-size: 22px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 14px;
    right: -15%;
  }

  .pageS-board-left {
    width: 65%;
    font-size: 14px;
  }
  .tagline {
    font-size: 18px;
    bottom: 2%;
    left: 10px;
  }
  /** PAGE A HORIZON **/

  .pageA-headlineLHS {
    font-size: 16px;
  }

  .pageA-headlineRHS {
    font-size: 14px;
    text-align: left;
  }

  .pageA-board-right {
    width: 63%;
    font-size: 14px;
    left: 18%;
  }

  .pageA-board-left {
    width: 65%;
    height: 40%;
    left: 17%;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 16px;
  }

  .pageB-headlineRHS {
    font-size: 14px;
  }

  .pageB-board-right {
    width: 61%;
    font-size: 14px;
    left: 19%;
  }

  .pageB-board-left {
    width: 65%;
    height: 40%;
    left: 19%;
  }

  .sl {
    height: 50%;
    width: 60%;
  }
  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 16px;
  }

  .pageC-headlineRHS {
    font-size: 16px;
  }

  .pageC-board-right {
    width: 72%;
    font-size: 14px;
    left: 15%;
    top: 90%;
  }

  .pageC-board-left {
    width: 70%;
    height: 65%;
    left: 15%;
    top: 21%;
  }
  /** PAGE D SPACE**/

  .pageD-headlineLHS {
    font-size: 16px;
  }

  .pageD-headlineRHS {
    font-size: 14px;
  }

  .pageD-board-right {
    width: 72%;
    height: 60%;
    top: 40%;
    right: -10%;
  }

  .pageD-board-left {
    width: 55%;
    height: 40%;
    font-size: 14px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  /** PAGE E INSIGHTS**/

  .pageE-headlineLHS {
    font-size: 16px;
  }

  .pageE-headlineRHS {
    font-size: 16px;
  }

  .pageE-board-right {
    width: 72%;
    height: 60%;
    top: 70%;
    right: 15%;
    font-size: 14px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 10%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 40%;
  }

  .pl {
    height: 9%;
    width: 50%;
  }

  /** PAGE F HARMONY**/

  .pageF-headlineLHS {
    font-size: 16px;
  }

  .pageF-headlineRHS {
    font-size: 14px;
  }

  .pageF-board-right {
    width: 72%;
    left: 20%;
    top: 50%;
  }

  .pageF-board-left {
    width: 65%;
    height: 40%;
    font-size: 14px;
    left: 20%;
    top: 50%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 16px;
  }

  .pageG-headlineRHS {
    font-size: 14px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 14px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: -20px;
    bottom: -150px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 7" to 9" tablets (landscape ) DONE **************************/

@media only screen and (min-width: 900px) and (max-width: 1024px) and (orientation: landscape) {
  .logo-main {
    top: 45px;
    left: 45px;
  }

  #root {
    background: radial-gradient(circle, #0e160d 0%, #0a1a07 5%, #000000 100%);
  }

  .navigation {
    height: 23%;
    width: 25%;
    bottom: 2%;
    right: 5%;
  }
  .material-icons {
    font-size: 22px;
  }
  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 16px;
  }

  .pageS-headlineRHS {
    font-size: 22px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 14px;
    right: -15%;
  }

  .pageS-board-left {
    width: 65%;
    font-size: 14px;
  }
  .tagline {
    font-size: 18px;
    bottom: 2%;
    left: 10px;
  }
  /** PAGE A HORIZON **/

  .pageA-headlineLHS {
    font-size: 16px;
  }

  .pageA-headlineRHS {
    font-size: 14px;
    text-align: left;
  }

  .pageA-board-right {
    width: 63%;
    font-size: 14px;
    left: 18%;
  }

  .pageA-board-left {
    width: 65%;
    height: 40%;
    left: 17%;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 16px;
  }

  .pageB-headlineRHS {
    font-size: 14px;
  }

  .pageB-board-right {
    width: 61%;
    font-size: 14px;
    left: 19%;
  }

  .pageB-board-left {
    width: 65%;
    height: 40%;
    left: 19%;
  }

  .sl {
    height: 50%;
    width: 60%;
  }
  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 16px;
  }

  .pageC-headlineRHS {
    font-size: 16px;
  }

  .pageC-board-right {
    width: 72%;
    font-size: 14px;
    left: 15%;
    top: 90%;
  }

  .pageC-board-left {
    width: 70%;
    height: 65%;
    left: 15%;
    top: 21%;
  }
  /** PAGE D SPACE**/

  .pageD-headlineLHS {
    font-size: 16px;
  }

  .pageD-headlineRHS {
    font-size: 14px;
  }

  .pageD-board-right {
    width: 72%;
    height: 60%;
    top: 40%;
    right: -12%;
  }

  .pageD-board-left {
    width: 55%;
    height: 40%;
    font-size: 14px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  /** PAGE E INSIGHTS**/

  .pageE-headlineLHS {
    font-size: 16px;
  }

  .pageE-headlineRHS {
    font-size: 16px;
  }

  .pageE-board-right {
    width: 55%;
    height: 60%;
    top: 70%;
    right: 22%;
    font-size: 14px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 10%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 20%;
  }

  .pl {
    height: 9%;
    width: 50%;
  }

  /** PAGE F HARMONY**/

  .pageF-headlineLHS {
    font-size: 16px;
  }

  .pageF-headlineRHS {
    font-size: 14px;
  }

  .pageF-board-right {
    width: 72%;
    left: 20%;
    top: 50%;
  }

  .pageF-board-left {
    width: 65%;
    height: 40%;
    font-size: 14px;
    left: 20%;
    top: 50%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 16px;
  }

  .pageG-headlineRHS {
    font-size: 14px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 14px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: -50px;
    bottom: -50px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 9" to 11" tablets (portrait) DONE **************************/

@media only screen and (min-width: 769px) and (max-width: 834px) and (orientation: portrait) {
  .logo-main {
    top: 45px;
    left: 45px;
  }

  #root {
    background: radial-gradient(circle, #0e160d 0%, #0a1a07 5%, #000000 100%);
  }

  .navigation {
    height: 20%;
    width: 36%;
    bottom: 2%;
    right: 5%;
  }
  .material-icons {
    font-size: 22px;
  }
  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 16px;
  }

  .pageS-headlineRHS {
    font-size: 22px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 16px;
    right: -15%;
  }

  .pageS-board-left {
    width: 65%;
    font-size: 16px;
  }
  .tagline {
    font-size: 27px;
    bottom: 2%;
    left: 3%;
  }
  /** PAGE A HORIZON **/

  .pageA-headlineLHS {
    font-size: 16px;
  }

  .pageA-headlineRHS {
    font-size: 14px;
    text-align: left;
  }

  .pageA-board-right {
    width: 63%;
    font-size: 16px;
    left: 18%;
  }

  .pageA-board-left {
    width: 65%;
    height: 40%;
    left: 17%;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 16px;
  }

  .pageB-headlineRHS {
    font-size: 14px;
  }

  .pageB-board-right {
    width: 61%;
    font-size: 16px;
    left: 19%;
  }

  .pageB-board-left {
    width: 65%;
    height: 40%;
    left: 19%;
  }

  .sl {
    height: 50%;
    width: 60%;
  }
  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 16px;
  }

  .pageC-headlineRHS {
    font-size: 16px;
  }

  .pageC-board-right {
    width: 72%;
    font-size: 16px;
    left: 15%;
    top: 90%;
  }

  .pageC-board-left {
    width: 70%;
    height: 65%;
    left: 15%;
    top: 21%;
  }
  /** PAGE D SPACE**/

  .pageD-headlineLHS {
    font-size: 16px;
  }

  .pageD-headlineRHS {
    font-size: 14px;
  }

  .pageD-board-right {
    width: 72%;
    height: 60%;
    top: 40%;
    right: -10%;
  }

  .pageD-board-left {
    width: 55%;
    height: 40%;
    font-size: 16px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  /** PAGE E INSIGHTS**/

  .pageE-headlineLHS {
    font-size: 16px;
  }

  .pageE-headlineRHS {
    font-size: 16px;
  }

  .pageE-board-right {
    width: 72%;
    height: 60%;
    top: 70%;
    right: 15%;
    font-size: 16px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 10%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 50%;
  }

  .pl {
    height: 9%;
    width: 50%;
  }

  /** PAGE F HARMONY**/

  .pageF-headlineLHS {
    font-size: 16px;
  }

  .pageF-headlineRHS {
    font-size: 14px;
  }

  .pageF-board-right {
    width: 72%;
    left: 20%;
    top: 50%;
  }

  .pageF-board-left {
    width: 65%;
    height: 40%;
    font-size: 16px;
    left: 20%;
    top: 50%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 16px;
  }

  .pageG-headlineRHS {
    font-size: 14px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 16px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: -10px;
    bottom: -250px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 9" to 11" tablets (landscape ) DONE **************************/

@media only screen and (min-width: 1025px) and (max-width: 1194px) and (orientation: landscape) {
  .logo-main {
    top: 45px;
    left: 45px;
  }

  #root {
    background: radial-gradient(circle, #0e160d 0%, #0a1a07 5%, #000000 100%);
  }

  .navigation {
    height: 23%;
    width: 25%;
    bottom: 2%;
    right: 5%;
  }
  .material-icons {
    font-size: 22px;
  }
  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 16px;
  }

  .pageS-headlineRHS {
    font-size: 22px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 16px;
    right: -15%;
  }

  .pageS-board-left {
    width: 50%;
    font-size: 16px;
  }
  .tagline {
    font-size: 27px;
    bottom: 2%;
    left: 3%;
  }
  /** PAGE A HORIZON **/

  .pageA-headlineLHS {
    font-size: 16px;
  }

  .pageA-headlineRHS {
    font-size: 14px;
    text-align: left;
  }

  .pageA-board-right {
    width: 63%;
    font-size: 16px;
    left: 18%;
  }

  .pageA-board-left {
    width: 65%;
    height: 40%;
    left: 17%;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 16px;
  }

  .pageB-headlineRHS {
    font-size: 14px;
  }

  .pageB-board-right {
    width: 61%;
    font-size: 16px;
    left: 19%;
  }

  .pageB-board-left {
    width: 65%;
    height: 40%;
    left: 19%;
  }

  .sl {
    height: 50%;
    width: 60%;
  }
  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 16px;
  }

  .pageC-headlineRHS {
    font-size: 16px;
  }

  .pageC-board-right {
    width: 72%;
    font-size: 16px;
    left: 15%;
    top: 90%;
  }

  .pageC-board-left {
    width: 70%;
    height: 65%;
    left: 15%;
    top: 21%;
  }
  /** PAGE D SPACE**/

  .pageD-headlineLHS {
    font-size: 16px;
  }

  .pageD-headlineRHS {
    font-size: 14px;
  }

  .pageD-board-right {
    width: 72%;
    height: 60%;
    top: 40%;
    right: -12%;
  }

  .pageD-board-left {
    width: 55%;
    height: 40%;
    font-size: 16px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  /** PAGE E INSIGHTS**/

  .pageE-headlineLHS {
    font-size: 16px;
  }

  .pageE-headlineRHS {
    font-size: 16px;
  }

  .pageE-board-right {
    width: 45%;
    height: 60%;
    top: 70%;
    right: 27%;
    font-size: 16px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 10%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 20%;
  }

  .pl {
    height: 9%;
    width: 50%;
  }

  /** PAGE F HARMONY**/

  .pageF-headlineLHS {
    font-size: 16px;
  }

  .pageF-headlineRHS {
    font-size: 14px;
  }

  .pageF-board-right {
    width: 72%;
    left: 20%;
    top: 50%;
  }

  .pageF-board-left {
    width: 65%;
    height: 40%;
    font-size: 16px;
    left: 20%;
    top: 50%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 16px;
  }

  .pageG-headlineRHS {
    font-size: 14px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 14px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 10%;
    bottom: 10px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 11" to 13" tablets (portrait) DONE **************************/

@media only screen and (min-width: 835px) and (max-width: 1024px) and (orientation: portrait) {
  .logo-main {
    top: 45px;
    left: 45px;
  }
  .navigation {
    height: 18%;
    width: 36%;
    bottom: 2%;
    right: 5%;
  }
  .material-icons {
    font-size: 22px;
  }
  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 18px;
  }

  .pageS-headlineRHS {
    font-size: 22px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 18px;
    right: -15%;
  }

  .pageS-board-left {
    width: 55%;
    font-size: 18px;
  }
  .tagline {
    font-size: 30px;
    bottom: 2%;
    left: 3%;
  }
  /** PAGE A HORIZON **/

  .pageA-headlineLHS {
    font-size: 18px;
  }

  .pageA-headlineRHS {
    font-size: 16px;
    text-align: left;
  }

  .pageA-board-right {
    width: 63%;
    font-size: 18px;
    left: 18%;
  }

  .pageA-board-left {
    width: 65%;
    height: 40%;
    left: 17%;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 18px;
  }

  .pageB-headlineRHS {
    font-size: 16px;
  }

  .pageB-board-right {
    width: 61%;
    font-size: 18px;
    left: 19%;
  }

  .pageB-board-left {
    width: 65%;
    height: 40%;
    left: 19%;
  }

  .sl {
    height: 50%;
    width: 60%;
  }
  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 18px;
  }

  .pageC-headlineRHS {
    font-size: 18px;
  }

  .pageC-board-right {
    width: 72%;
    font-size: 18px;
    left: 14%;
    top: 90%;
  }

  .pageC-board-left {
    width: 70%;
    height: 65%;
    left: 15%;
    top: 21%;
  }
  /** PAGE D SPACE**/

  .pageD-headlineLHS {
    font-size: 18px;
  }

  .pageD-headlineRHS {
    font-size: 16px;
  }

  .pageD-board-right {
    width: 72%;
    height: 60%;
    top: 40%;
    right: -12%;
  }

  .pageD-board-left {
    width: 55%;
    height: 40%;
    font-size: 18px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  /** PAGE E INSIGHTS**/

  .pageE-headlineLHS {
    font-size: 18px;
  }

  .pageE-headlineRHS {
    font-size: 18px;
  }

  .pageE-board-right {
    width: 55%;
    height: 60%;
    top: 70%;
    right: 24%;
    font-size: 18px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 10%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 40%;
  }

  .pl {
    height: 9%;
    width: 50%;
  }

  /** PAGE F HARMONY**/

  .pageF-headlineLHS {
    font-size: 18px;
  }

  .pageF-headlineRHS {
    font-size: 18px;
  }

  .pageF-board-right {
    width: 72%;
    left: 20%;
    top: 50%;
  }

  .pageF-board-left {
    width: 65%;
    height: 40%;
    font-size: 18px;
    left: 20%;
    top: 50%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 18px;
  }

  .pageG-headlineRHS {
    font-size: 18px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 18px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: -10px;
    bottom: -250px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}

/********************* 11" to 13" tablets (landscape ) **************************/

@media only screen and (min-width: 1195px) and (max-width: 1367px) and (orientation: landscape) {
  .logo-main {
    top: 45px;
    left: 45px;
  }
  .navigation {
    height: 21%;
    width: 24%;
    bottom: 2%;
    right: 5%;
  }
  .material-icons {
    font-size: 22px;
  }
  /** PAGE S **/

  .pageS-headlineLHS {
    font-size: 18px;
  }

  .pageS-headlineRHS {
    font-size: 22px;
  }

  .pageS-board-right {
    width: 75%;
    font-size: 18px;
    right: -15%;
  }

  .pageS-board-left {
    width: 55%;
    font-size: 18px;
  }
  .tagline {
    font-size: 30px;
    bottom: 2%;
    left: 3%;
  }
  /** PAGE A HORIZON **/

  .pageA-headlineLHS {
    font-size: 18px;
  }

  .pageA-headlineRHS {
    font-size: 16px;
    text-align: left;
  }

  .pageA-board-right {
    width: 63%;
    font-size: 18px;
    left: 18%;
  }

  .pageA-board-left {
    width: 65%;
    height: 40%;
    left: 17%;
  }

  /** PAGE B GENESIS **/

  .pageB-headlineLHS {
    font-size: 18px;
  }

  .pageB-headlineRHS {
    font-size: 16px;
  }

  .pageB-board-right {
    width: 61%;
    font-size: 18px;
    left: 19%;
  }

  .pageB-board-left {
    width: 65%;
    height: 40%;
    left: 19%;
  }

  .sl {
    height: 50%;
    width: 60%;
  }
  /** PAGE C THRESHOLD **/

  .pageC-headlineLHS {
    font-size: 18px;
  }

  .pageC-headlineRHS {
    font-size: 18px;
  }

  .pageC-board-right {
    width: 72%;
    font-size: 18px;
    left: 14%;
    top: 90%;
  }

  .pageC-board-left {
    width: 70%;
    height: 65%;
    left: 15%;
    top: 21%;
  }
  /** PAGE D SPACE**/

  .pageD-headlineLHS {
    font-size: 18px;
  }

  .pageD-headlineRHS {
    font-size: 18px;
  }

  .pageD-board-right {
    width: 72%;
    height: 60%;
    top: 40%;
    right: -10%;
  }

  .pageD-board-left {
    width: 55%;
    height: 40%;
    font-size: 18px;
  }
  .ff {
    height: 60%;
    width: 60%;
  }

  /** PAGE E INSIGHTS**/

  .pageE-headlineLHS {
    font-size: 18px;
  }

  .pageE-headlineRHS {
    font-size: 18px;
  }

  .pageE-board-right {
    width: 55%;
    height: 60%;
    top: 70%;
    right: 23%;
    font-size: 18px;
  }

  .pageE-board-left {
    width: 65%;
    height: 40%;
    left: 10%;
  }

  .pl-exodus {
    height: 10%;
    width: 50%;
  }

  .pl-ecolith {
    height: 10%;
    width: 50%;
    margin-bottom: 20%;
  }

  .pl {
    height: 9%;
    width: 50%;
  }

  /** PAGE F HARMONY**/

  .pageF-headlineLHS {
    font-size: 18px;
  }

  .pageF-headlineRHS {
    font-size: 18px;
  }

  .pageF-board-right {
    width: 72%;
    left: 20%;
    top: 50%;
  }

  .pageF-board-left {
    width: 65%;
    height: 40%;
    font-size: 18px;
    left: 18%;
    top: 50%;
  }

  /** PAGE G REACH **/

  .pageG-headlineLHS {
    font-size: 18px;
  }

  .pageG-headlineRHS {
    font-size: 16px;
  }

  .pageG-board-right {
    width: 80%;
    font-size: 18px;
    top: 55%;
  }

  .pageG-board-left {
    width: 36%;
    height: 30%;
    left: 33%;
  }

  .whatsapp-code {
    background-image: url("/public/Assets/Images/QRCODE.jpeg");
    height: 150px;
    width: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px;
  }

  .powered {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: -10px;
    bottom: -180px;
  }

  .sense-logo {
    background-image: url("/public/Assets/Images/sense-logo.png");
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 5px;
  }
}
