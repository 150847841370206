.loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
  margin-top: 300px;
}

.loading-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.to-load {
  position: absolute;
  height: 0.9px;
  width: 666px;
  background-color: rgb(108, 108, 108);
}

.load-percentage {
  position: absolute;
  height: 1.8px;
  width: 0px;
  background-color: rgb(255, 255, 255);
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #081207;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 133, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 630px) and (max-width: 900px) {
  .loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 50%;
    margin-top: 120px; /** Need to be assessed for each device view-port **/
  }
  .to-load {
    position: absolute;
    height: 0.9px;
    width: 261px;
    background-color: rgb(108, 108, 108);
  }
}
